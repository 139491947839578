/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ( $ ) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				// JavaScript to be fired on all pages

				fakewaffle.responsiveTabs( ['xs', 'sm'] );

				if ( $( '.table-sort' ).length > 0 ) {
					$( '.table-sort' ).dataTable( {
						'paging': false,
						'info': false,
						'searching': false
					} );
				}

				$( window ).on( "load resize scroll", function () {
					if ( $( document ).scrollTop() > $( 'header' ).outerHeight() ) {
						$( 'nav.navbar' ).addClass( 'navbar-fixed-top scrolled' );
					} else {
						$( 'nav.navbar' ).removeClass( 'navbar-fixed-top' );
					}
				} );

			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
				$( ".nav-toggle" ).click( function ( event ) {
					var scrollHeight = $( window ).scrollTop();
					$( ".nav-wrapper, .nav-toggle, body" ).toggleClass( "open" );
					event.preventDefault();
					if ( $( '.nav-toggle' ).hasClass( 'open' ) ) {
						$( '.nav-toggle' ).attr( 'data-height', scrollHeight );
					} else {
						$( window ).scrollTop( $( '.nav-toggle' ).attr( 'data-height' ) );
					}
				} );
			}
		},
		// Home page
		'home': {
			init: function () {
				// JavaScript to be fired on the home page
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
				// $( 'div ul.navbar-nav li.menu-item-has-children > a' ).on( 'click', function ( e ) {
				// 	if ( $( this ).parent().hasClass( 'open' ) ) {
				// 		window.location = $( this ).attr( 'href' );
				// 	} else {
				// 		e.preventDefault();
				// 	}
				// } );
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function ( func, funcname, args ) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if ( fire ) {
				namespace[func][funcname]( args );
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire( 'common' );

			// Fire page-specific init JS, and then finalize JS
			$.each( document.body.className.replace( /-/g, '_' ).split( /\s+/ ), function ( i, classnm ) {
				UTIL.fire( classnm );
				UTIL.fire( classnm, 'finalize' );
			} );

			// Fire common finalize JS
			UTIL.fire( 'common', 'finalize' );
		}
	};

	// Load Events
	$( document ).ready( UTIL.loadEvents );

})( jQuery ); // Fully reference jQuery after this point.
